<template>
  <div class="common-product">
    <div class="category-list">
      <div class="title">{{ $t('fen-lei') }}</div>
      <div class="list-box">
        <div
          class="list"
          @click="showAll"
          :class="form.category == '' ? 'active' : ''"
        >
          <div class="label">{{ $t('quan-bu-shang-pin') }}</div>
        </div>
        <div
          class="list"
          v-for="(item, i) in categoryList"
          :key="i"
          :class="form.category == item.ID ? 'active' : ''"
        >
          <div class="label" @click="changeShow(item, i)">
            {{ item.title }}
            <i
              class="el-icon-arrow-right"
              v-if="item.children && item.children.length > 0 && !item.show"
            ></i>
            <i
              class="el-icon-arrow-down"
              v-if="item.children && item.children.length > 0 && item.show"
            ></i>
          </div>
          <div class="child-box" :class="item.show ? 'show' : ''">
            <div
              class="sub-list"
              v-for="(subItem, index) in item.children"
              :key="index"
            >
              <div
                class="sub-label"
                @click="changeShowSub(subItem, i, index)"
                :class="form.category == subItem.ID ? 'active' : ''"
              >
                {{ subItem.title }}
                <i
                  class="el-icon-arrow-right"
                  v-if="
                    subItem.children &&
                    subItem.children.length > 0 &&
                    !subItem.show
                  "
                ></i>
                <i
                  class="el-icon-arrow-down"
                  v-if="
                    subItem.children &&
                    subItem.children.length > 0 &&
                    subItem.show
                  "
                ></i>
              </div>

              <div class="category-box" :class="subItem.show ? 'show' : ''">
                <div
                  class="category"
                  v-for="(category, m) in subItem.children"
                  :key="m"
                  :class="form.category == category.ID ? 'active' : ''"
                  @click="chooseCategory(category)"
                >
                  {{ category.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="goods-main">
      <div class="top-filter flex-center-start">
        <div
          class="option"
          :class="tabIndex == 1 ? 'active' : ''"
          @click="changeTab(1)"
        >
          {{ $t('zong-he') }}
        </div>
        <div
          class="option flex-center"
          :class="tabIndex == 2 ? 'active' : ''"
          @click="changeTab(2)"
        >
          {{ $t('xiao-shou-liang') }}
          <div class="flex-column-center icon">
            <i
              class="el-icon-caret-top"
              :class="tabIndex == 2 && sortType == 'asc' ? 'active' : ''"
            ></i>
            <i
              class="el-icon-caret-bottom"
              :class="tabIndex == 2 && sortType == 'desc' ? 'active' : ''"
            ></i>
          </div>
        </div>
        <div
          class="option flex-center"
          :class="tabIndex == 3 ? 'active' : ''"
          @click="changeTab(3)"
        >
          {{ $t('jia-ge') }}
          <div class="flex-column-center icon">
            <i
              class="el-icon-caret-top"
              :class="tabIndex == 3 && sortType == 'asc' ? 'active' : ''"
            ></i>
            <i
              class="el-icon-caret-bottom"
              :class="tabIndex == 3 && sortType == 'desc' ? 'active' : ''"
            ></i>
          </div>
        </div>
        <div
          class="option flex-center"
          :class="tabIndex == 4 ? 'active' : ''"
          @click="changeTab(4)"
        >
          {{ $t('shang-xin') }}
          <div class="flex-column-center icon">
            <i
              class="el-icon-caret-top"
              :class="tabIndex == 4 && sortType == 'asc' ? 'active' : ''"
            ></i>
            <i
              class="el-icon-caret-bottom"
              :class="tabIndex == 4 && sortType == 'desc' ? 'active' : ''"
            ></i>
          </div>
        </div>
      </div>
      <goodsList class="line-5" :list="goodsList"></goodsList>

      <div class="flex-center user-pager mt-20">
        <el-pagination
          background
          @current-change="currentChange"
          :current-page="page.current"
          :page-size="page.size"
          layout=" prev, pager,  next"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import goodsList from '@/components/goodsList'
import { shopProductList } from '@/api/shop'
export default {
  components: {
    goodsList
  },
  data() {
    return {
      tabIndex: 1,
      sortType: 'desc',
      goodsList: [],
      form: {
        sort: ''
      },
      page: {
        current: 1,
        size: 30,
        total: 0
      }
    }
  },
  computed: {
    lang() {
      return this.$store.state.lang
    },
    categoryList() {
      return this.$store.state.categoryList
    }
  },
  mounted() {
    let id = this.$route.query.id
    this.form.category = id
    this.init()
    this.initGoods()
  },
  methods: {
    init() {
      this.$store.dispatch('initCategory', this.lang)
    },
    currentChange(page) {
      this.page.current = page
      this.initGoods()
    },
    initGoods() {
      shopProductList({
        current: this.page.current,
        pageSize: this.page.size,
        CategoryID: this.form.category || null,
        Sort: this.form.sort
      }).then((res) => {
        this.goodsList = res.data.Items
        this.page.total = res.data.Pagination.totalRecords
      })
    },
    changeShow(data, i) {
      let show = !data.show
      this.$set(this.categoryList[i], 'show', show)
      this.form.category = data.ID
      this.page.current = 1
      this.initGoods()
    },
    changeShowSub(data, i, index) {
      if (!data.children || data.children.length == 0) {
        return
      }
      let show = !data.show
      this.$set(this.categoryList[i].children[index], 'show', show)
      this.form.category = data.ID
      this.page.current = 1
      this.initGoods()
    },
    changeTab(i) {
      if (i == this.tabIndex) {
        this.sortType = this.sortType == 'asc' ? 'desc' : 'asc'
      } else {
        this.sortType = 'desc'
        this.tabIndex = i
      }
      this.form.sort =
        i == 1
          ? ''
          : i == 2
          ? 'sales'
          : i === 3
          ? this.sortType == 'desc'
            ? 'MaxPrice'
            : 'MinPrice'
          : ''
      this.page.current = 1
      this.initGoods()
    },
    chooseCategory(data) {
      this.form.category = data.ID
      this.page.current = 1
      this.initGoods()
    },
    showAll() {
      this.form.category = ''
      this.page.current = 1
      this.initGoods()
    }
  }
}
</script>